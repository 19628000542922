import styled from "styled-components"

export const Container = styled.section`
  margin-top: ${props => (props.first ? "90px" : props.top ? "50px" : "20px")};
  margin-bottom: ${props => (props.marginBottomLess ? 0 : "90px")};

  @media screen and (min-width: 1024px) {
    margin-top: ${props => (props.top ? "50px" : props.sm ? "30px" : "160px")};
    margin-bottom: ${props => (props.marginBottomLess ? 0 : "160px")};
  }
`
