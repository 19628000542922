import React from "react"
import PropTypes from "prop-types"

import { Container } from "./styles"

const Section = ({ children, first, marginBottomLess, top, sm, ...rest }) => {
  return (
    <Container
      className="l-container"
      first={first}
      marginBottomLess={marginBottomLess}
      top={top}
      sm={sm}
      {...rest}
    >
      {children}
    </Container>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  first: PropTypes.bool,
  marginBottomLess: PropTypes.bool,
  top: PropTypes.bool,
  sm: PropTypes.bool,
}

Section.defaultProps = {
  first: false,
  marginBottomLess: false,
  top: false,
  sm: false,
}

export default Section
